import { InteractionConfig } from './types';
import { AnimatePresence, motion } from 'framer-motion';

export default function Interaction(props: {
	shouldPlay: boolean;
	config: InteractionConfig;
	onLoaded: () => void;
	onDone: () => void;
	onError: (e: Error) => void;
}) {
	// call after preloading is done
	props.onLoaded();
	// call onDone when your interaction is done or after some timeout
	setTimeout(() => {
		props.onDone();
	}, props.config.timeout);

	return (
		<AnimatePresence>
			<div className="relative w-full h-full">
				<audio src="/notif.mp3" autoPlay={true} />
				<motion.div

					initial={{ opacity: 0, y: 50 }}
					exit={{ opacity: 0, y: 50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.2, delay: 0 }}
				>
					<div className="flex items-center space-x-4 bg-gray-200 border-gray-300 rounded-xl p-4 shadow-md">
						{/* <div className=''> Updated to items-center */}
							<img alt="jesus" src='./logo.png' className='h-16'></img>
							<div className='min-w-[400px] max-w-4xl pr-20'>
								<div className='flex space-x-[35%]'>
									<h2 className="text-md font-a font-bold">Tangia #Chat</h2>
									<p className='text-xs mt-[-2%] text-gray-500 ml-[10%]'>1 minute ago</p>
								</div>

								<p className="text-left text-sm sm:text-md lg:text-lg font-a text-overflow: ellipse;">
									{props.config.msgFrom}: {props.config.msg}</p>


							</div>
					</div>
				</motion.div>
			</div>
		</AnimatePresence>
	);
}
